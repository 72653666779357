import { BorderRadiuses } from "./themeTypes";

export const borderRadiusesStrict: BorderRadiuses = {
  '--border-radius-block': '3px',
  '--border-radius-roundBox': '3px',
  '--border-radius-circle': '50%',
  '--border-radius-chat': '3px',
};


export const borderRadiusesRound: BorderRadiuses = {
  '--border-radius-block': '16px',
  '--border-radius-roundBox': '32px',
  '--border-radius-circle': '50%',
  '--border-radius-chat': '20px',
};

