import { Theme } from 'src/styles/themes/themeTypes';

export function createThemeStyles(themeObj: Theme) {
  let themeStyles = ':root {';

  for (const variable in themeObj) {
    const value = themeObj[variable];

    themeStyles += `${variable}: ${value};`;
  }

  themeStyles += '}';

  return themeStyles;
}
